import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { object, shape, string } from "prop-types";

// Constants
import { TECHNICAL_SUMMARY_CONFIGS } from "constants/processDetails/configs";
import { PROCESS_DETAILS_INPUT_NAMES } from "constants/processDetails";

// Contexts
import UserContext from "contexts/userContext";
import { ProcessDetailsContext } from "contexts/processDetailsContext";

// Services
import { getBoolean } from "services/util/auxiliaryUtils";
import { getPVPanels } from "api/processes";
import useNotifications from "hooks/notifications";
import { OK } from "constants/statusCodeHttp";
import IntlMessages from "components/util/IntlMessages";

const {
    SIMULATION_OUTPUTS,
    HAS_BATTERY,
    BATTERY_MANUFACTURER,
    // BATTERY_DESCRIPTION,
    BATTERY_MODEL,
    BATTERY_CAPACITY_KWH,
    BATTERY_POWER_KW,
    BATTERY_INVERTER_POWER_KW,
    BATTERY_NR_OF_INVERTERS,
    BATTERY_INVERTER_IS_HYBRID,
    TECHNICAL_BRIEF,
    CONTRACT_ID
} = PROCESS_DETAILS_INPUT_NAMES;

const TechnicalSummaryContext = createContext({
    configTechnicalInformation: object,
    batteryInfo: shape({
        [BATTERY_MANUFACTURER]: string,
        [BATTERY_MODEL]: string,
        [BATTERY_CAPACITY_KWH]: string,
        [BATTERY_POWER_KW]: string,
        [BATTERY_INVERTER_POWER_KW]: string,
        [BATTERY_NR_OF_INVERTERS]: string
    }),
    product: string,
    preValues: object,
    posValues: object
});

const TechnicalSummaryContextProvider = ({ children }) => {

    const { toastNotify } = useNotifications();

    const {
        userTypeID,
        userCompanyID
    } = useContext(UserContext);

    const {
        processDetails,
        processDetailsTab
    } = useContext(ProcessDetailsContext)

    const [pvPanelsOptions, setPVPanelsOptions] = useState(false);
    const [isRequestingPVPanels, setIsRequestingPVPanels] = useState(false);

    const configTechnicalInformation = useMemo(() => (
        TECHNICAL_SUMMARY_CONFIGS?.[userCompanyID]?.[userTypeID]?.[processDetails?.[processDetailsTab]?.product_type_id]
    ), [processDetails, userCompanyID, userTypeID, processDetailsTab]);

    // Is the same for all simulation Outputs Array, so it should be the 0(first) element
    const batteryInfo = useMemo(() => (
        getBoolean(processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS]?.[0]?.[HAS_BATTERY]) ? {
            [BATTERY_MANUFACTURER]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_MANUFACTURER],
            [BATTERY_MODEL]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_MODEL],
            [BATTERY_CAPACITY_KWH]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_CAPACITY_KWH],
            [BATTERY_POWER_KW]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_POWER_KW],
            [BATTERY_INVERTER_POWER_KW]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_INVERTER_POWER_KW],
            [BATTERY_NR_OF_INVERTERS]: processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_NR_OF_INVERTERS],
            [BATTERY_INVERTER_IS_HYBRID]: getBoolean(processDetails?.[processDetailsTab]?.[SIMULATION_OUTPUTS][0]?.[BATTERY_INVERTER_IS_HYBRID])
        } : null
    ), [processDetails, processDetailsTab]);

    const product = useMemo(() => processDetails?.[processDetailsTab]?.description, [processDetails, processDetailsTab]);

    const preValues = useMemo(() => processDetails?.[processDetailsTab]?.pre?.[TECHNICAL_BRIEF], [processDetails, processDetailsTab]);
    const posValues = useMemo(() => processDetails?.[processDetailsTab]?.pos?.[TECHNICAL_BRIEF], [processDetails, processDetailsTab]);

    const reformulateValues = useMemo(() => processDetails?.filter(process => process?.is_reformulated).map(process => process?.pre?.[TECHNICAL_BRIEF]), [processDetails]);
    const oldProposalValues = useMemo(() => ({
        pre: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pre?.[TECHNICAL_BRIEF],
        pos: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.pos?.[TECHNICAL_BRIEF],
        contractID: processDetails?.find(process => !process?.[PROCESS_DETAILS_INPUT_NAMES.IS_REFORMULATED])?.[CONTRACT_ID]
    }), [processDetails])

    const fetchPVPanels = async () => {
        setIsRequestingPVPanels(true);
        let rpsPVPanels = await getPVPanels();

        if (rpsPVPanels?.status !== OK) {
            toastNotify('error', <IntlMessages id={"server.error.unexpectedError"} />)
            return;
        }
        setPVPanelsOptions(rpsPVPanels.data.data)
        setIsRequestingPVPanels(false);
    };

    useEffect(() => {
        fetchPVPanels()
    },[])//eslint-disable-line

    return (
        <TechnicalSummaryContext.Provider
            value={{
                configTechnicalInformation,
                batteryInfo,
                product,
                preValues,
                posValues,
                processDetails: processDetails?.[processDetailsTab],
                reformulateValues,
                oldProposalValues,
                pvPanelsOptions,
                isRequestingPVPanels
            }}
        >
            {children}
        </TechnicalSummaryContext.Provider>
    )
}

export { TechnicalSummaryContext, TechnicalSummaryContextProvider }